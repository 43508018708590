<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body class="mt-md-1">
        <b-overlay variant="white" spinner-variant="primary" blur="0" opacity=".75" rounded="sm" :show="isLoading">
          <div>
            <b-row>
              <b-col cols="7">
                <b-form-file
                  ref="inputFileRef"
                  placeholder="Chọn file để import câu hỏi khảo sát cho các phiếu khảo sát/đánh giá..."
                  drop-placeholder="Drop file here..."
                  accept=".xls, .xlsx"
                  @change="importExcel"
                />
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                  v-show="showTableImport && (isSystemAdmin() || creatable)"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="info"
                    class="ml-1"
                    @click="handleImportSetOfCriteria"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="CheckSquareIcon" /> Import
                    </span>
                  </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="ml-1"
                      @click="downloadFile"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="DownloadIcon" /> Tải file mẫu
                      </span>
                    </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="ml-1 mr-1"
                    @click="exportExcel"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="DownloadIcon" /> Xuất excel
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="m-1">
            <b-row>
              <b-col cols="8" md="8"><h3>Danh sách câu hỏi thuộc về phiếu</h3><b>{{ dataSend.name + " - " + dataSend.code}}</b></b-col>
              <b-col cols="4" md="4">
                <div class="text-right">
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.criteriaSaveModal
                    variant="success"
                    class="ml-1"
                    @click="onCreateCriteria"
                  >
                    <span class="text-nowrap text-right d-none d-lg-block">
                      <feather-icon icon="PlusIcon" /> Thêm câu hỏi khảo sát/đánh giá
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  styleClass="vgt-table striped bordered"
                  :line-numbers="true"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  max-height="400px"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'criteriaType'"
                          :class="props.row.criteriaType?'text-success':'text-danger'"
                          v-html="getCriteriaTypeName(props.row.criteriaType)"
                    ></span>

                    <span v-else-if="props.column.field === 'action'" class="thead-group-action">
                      <b-button-group>
                        <b-button
                          v-if="updatable"
                          v-b-modal.criteriaSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          title="Chỉnh sửa"
                          @click="onEditMainData(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-button
                          v-if="deletable"
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          title="Xóa"
                          @click="onDeleteMainData(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
          <CriteriaSave
            ref="criteriaSaveFormRef"
            :mainData="currentMainData"
            :setOfCriteriaId="filter.setOfCriteriaId"
            :setOfEvaluateLevelId="dataSend.setOfEvaluateLevelId"
            :evaluateType="dataSend.evaluateType"
            :allowEditEvaluationCriteria="dataSend.allowEditEvaluationCriteria"
            :countSetOfCriteriaObjectUsing="dataSend.countSetOfCriteriaObjectUsing"
            @succeed="onSucceed"
          />
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
  BFormFile,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import CriteriaSave from '@/views/set-of-criterias/CriteriaSave.vue'
import { PermissionCode, ResourceCode } from '@/const/code'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import * as XLSX from 'xlsx'
import { Workbook } from '@syncfusion/ej2-excel-export'
import { getUser } from '@/auth/utils'
import { CRITERIA_TYPES, REQUIRED, MULTIPLE_CRITERIA_OPTION_TYPES } from '@/const/type'


export default {
  name: 'Criterias',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    CriteriaSave,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormFile,
  },
  props: {
    dataSend: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        organizationId: null,
        setOfCriteriaId: null,
        currentPage: 1,
        itemsPerPage: 10,
        criteriaType: '',
        name: '',
        sort: null,
      },
      currentMainData: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      filterExport: null,
      showTableImport: false,
      tenFile: '',
      CRITERIA_TYPES,
      columns: [
        {
          label: 'Tên câu hỏi',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Loại câu hỏi',
          field: 'criteriaType',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Điểm tối đa',
          field: 'maxPoint',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Số thứ tự',
          field: 'orderNo',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      columnsEvaluationCriteriaExport: [
        {
          label: 'Mã phiếu khảo sát/đánh giá',
          field: 'code',
        },
        {
          label: 'Loại câu hỏi',
          field: 'criteriaType',
        },
        {
          label: 'Tên câu hỏi',
          field: 'criteriaName',
        },
        {
          label: 'Điểm tối đa',
          field: 'maxPoint',
        },
        {
          label: 'Số thứ tự',
          field: 'orderNo',
        },
        {
          label: 'Yêu cầu',
          field: 'required',
        },
        {
          label: 'Có thể chọn nhiều đáp án',
          field: 'multipleSelect',
        },
        {
          label: 'Tên tuỳ chọn',
          field: 'optionName',
        },
        {
          label: 'Điểm tuỳ chọn',
          field: 'point',
        },
        {
          label: 'Số thức tự tuỳ chọn',
          field: 'noAnswer',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      mainData: 'evaluationCriteria/dataLists',
      totalRows: 'evaluationCriteria/totalRows',
      dataResourecesExport: 'evaluationCriteria/dataResourecesExport',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.EVALUATION_CRITERIAS)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.EVALUATION_CRITERIAS)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.EVALUATION_CRITERIAS)
    },
  },
  async created() {
    this.isLoading = true
    try {
      this.updateParams({ organizationId: this.user.orgId, setOfCriteriaId: this.dataSend.id })
      this.getMainData(this.filter)
      const {
          currentPage,
          itemsPerPage,
          ...rest
        } = this.filter
        this.filterExport = rest
        await this.getDataResourcesExport(this.filterExport)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    isSystemAdmin,
    ...mapActions({
      getMainData: 'evaluationCriteria/getData',
      deleteMainData: 'evaluationCriteria/deleteData',
      getDataResourcesExport: 'evaluationCriteria/getDataResourcesExport',
      importCriteria: 'evaluationCriteria/importCriteria',
    }),
    onCreateCriteria() {
      this.currentMainData = undefined
    },
    onEditMainData(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xoá bỏ <span class="text-danger">${mainData.name}</span> khỏi CTĐT này?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        size: 'xl',
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainData(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
        const {
          currentPage,
          itemsPerPage,
          ...rest
        } = this.filter
        this.filterExport = rest
        await this.getDataResourcesExport(this.filterExport)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'criteriaType')) this.updateParams({ criteriaType: columnFilters.criteriaType })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      await this.getMainDataFromStore()
    },
    async onSucceed() {
      await this.getMainDataFromStore()
      this.$bvModal.hide('modal-replace-thesis-subject')
    },
    getCriteriaTypeName(id) {
      return id >= 0 ? CRITERIA_TYPES.find(item => item.value === id).label : ''
    },
    getCriteriaRequired(id) {
      return id >= 0 ? REQUIRED.find(item => item.value === id).label : ''
    },
    getMutipleCriteriaOptionType(id) {
      return id >= 0 ? MULTIPLE_CRITERIA_OPTION_TYPES.find(item => item.value === id)?.label : ''
    },
    downloadFile() {
      const columns = [
        { index: 1, width: 100 },
        { index: 2, width: 50 },
        { index: 3, width: 300 },
        { index: 4, width: 50 },
        { index: 5, width: 50 },
        { index: 6, width: 50 },
        { index: 7, width: 50 },
        { index: 8, width: 100 },
        { index: 9, width: 50 },
        { index: 10, width: 50 },
      ]
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'MA_PHIEU_KHAO_SAT_DANH_GIA',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 2,
            value: 'LOAI_CAU_HOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 3,
            value: 'TEN_CAU_HOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 4,
            value: 'MA_CAU_HOI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 5,
            value: 'DIEM_TOI_DA',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 6,
            value: 'SO_THU_TU',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 7,
            value: 'YEU_CAU',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 8,
            value: 'CO_THE_CHON_NHIEU_DAP_AN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 9,
            value: 'MA_CAU_HOI_CHA',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 10,
            value: 'TEN_TUY_CHON',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 11,
            value: 'DIEM_TUY_CHON',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 12,
            value: 'SO_THU_TU_TUY_CHON',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
        ],
      }
      const headerRow1 = {
        index: 2,
        cells: [
          {
            index: 1,
            value: 'BTCC2023',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 1,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 'GV có phương pháp truyền đạt rõ ràng, dễ hiểu',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'CH1',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: 5,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 6,
            value: 4,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 7,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 8,
            value: 0,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 9,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 10,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 11,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 12,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }

      const headerRow2 = {
        index: 3,
        cells: [
          {
            index: 1,
            value: 'BTCC2023',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 1,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 'GV phối hợp hiệu quả các phương pháp giảng dạy',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'CH2',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: 8,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 6,
            value: 3,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 7,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 8,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 9,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 10,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 11,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 12,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow3 = {
        index: 4,
        cells: [
          {
            index: 1,
            value: 'BTCC2023',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 2,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 'Tỷ lệ thời gian tham dự lớp của Anh/Chị cho học phần này là:',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'CH3',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: 5,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 6,
            value: 2,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 7,
            value: 1,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 8,
            value: 0,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 9,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 10,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 11,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 12,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow4 = {
        index: 5,
        cells: [
          {
            index: 1,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 6,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 7,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 8,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 9,
            value: 'CH3',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 10,
            value: 'Dưới 50%',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 11,
            value: 1,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 12,
            value: 1,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow5 = {
        index: 6,
        cells: [
        {
            index: 1,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 6,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 7,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 8,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 9,
            value: 'CH3',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 10,
            value: 'Từ 50% - 65%',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 11,
            value: 2,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 12,
            value: 2,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow6 = {
        index: 7,
        cells: [
          {
            index: 1,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 6,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 7,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 8,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 9,
            value: 'CH3',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 10,
            value: 'Từ 65% - 80%',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 11,
            value: 3,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 12,
            value: 3,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow7 = {
        index: 8,
        cells: [
          {
            index: 1,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 6,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 7,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 8,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 9,
            value: 'CH3',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 10,
            value: 'Từ 80% - 90%',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 11,
            value: 4,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 12,
            value: 4,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow8 = {
        index: 9,
        cells: [
          {
            index: 1,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 6,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 7,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 8,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 9,
            value: 'CH3',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 10,
            value: 'Từ 90% trở lên',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 11,
            value: 5,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 12,
            value: 5,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }

      const headerRow9 = {
        index: 10,
        cells: [
          {
            index: 1,
            value: 'BTCC2023',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 3,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 'Bạn có ý kiến gì đóng góp thêm không ?',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'CH4',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 6,
            value: 5,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 7,
            value: 0,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 8,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 9,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 10,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 11,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 12,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow10 = {
        index: 11,
        cells: [
          {
            index: 1,
            value: 'BTCC2023',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 3,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 'Bạn có yêu thích học phần này không ?',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'CH5',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 6,
            value: 6,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 7,
            value: 1,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 8,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 9,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 10,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 11,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 12,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow12 = {
        index: 13,
        cells: [
          {
            index: 1,
            colSpan: 1,
          },
          {
            index: 2,
            value: '1: Thanh kéo điểm, 2: Lựa chọn, 3: Tự luận',
            colSpan: 1,
          },
          {
            index: 3,
            colSpan: 1,
          },
          {
            index: 4,
            colSpan: 1,
          },
          {
            index: 5,
            colSpan: 1,
          },
          {
            index: 6,
            colSpan: 1,
          },
          {
            index: 7,
            value: '1: Có bắt buộc trả lời, 0: Không bắt buộc trả lời',
            colSpan: 1,
          },
          {
            index: 8,
            value: 'Đối với câu hỏi loại lựa chọn (1: Có thể chọn nhiều, 0: Không thể chọn nhiều)',
            colSpan: 1,
          },
          {
            index: 9,
            colSpan: 1,
          },
          {
            index: 10,
            colSpan: 1,
          },
          {
            index: 11,
            colSpan: 1,
          },
          {
            index: 12,
            colSpan: 1,
          },
        ],
      }

      rows.push(headerRow)
      rows.push(headerRow1)
      rows.push(headerRow2)
      rows.push(headerRow3)
      rows.push(headerRow4)
      rows.push(headerRow5)
      rows.push(headerRow6)
      rows.push(headerRow7)
      rows.push(headerRow8)
      rows.push(headerRow9)
      rows.push(headerRow10)
      rows.push(headerRow12)
      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')
      workbook.save('cauhoiKhaoSatDanhGia.xlsx')
    },
    exportExcel() {
      if (this.dataResourecesExport.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }

      let excelData = []
      let data = []
      this.dataResourecesExport.forEach((criteria) => {
        data.push({'code': criteria.code, 'criteriaType': this.getCriteriaTypeName(criteria.criteriaType), 'criteriaName': criteria.evaluationCriteriaName, 'maxPoint': criteria.maxPoint, 'orderNo': criteria.evaluationCriteriaOrderNo, 'required': this.getCriteriaRequired(criteria.required), 'multipleSelect': this.getMutipleCriteriaOptionType(criteria.multipleSelect), 'optionName': '', 'point': '', 'noAnswer': '' })
        criteria.options?.forEach((option) => {
          data.push({'code': '', 'criteriaType': '', 'criteriaName': '', 'maxPoint': '', 'orderNo': '', 'required': '', 'multipleSelect': '', 'optionName': option.name, 'point': option.point, 'noAnswer': option.orderNo })
        })
      })
      

      const header = {}
      this.columnsEvaluationCriteriaExport.forEach(column => {
        header[column.field] = column.label
      })

      const headerKeys = Object.keys(header)
      const dataSourceKeys = Object.keys(data[0])
      const duplicates = dataSourceKeys.filter(val => headerKeys.includes(val))
      duplicates.sort((a, b) => headerKeys.indexOf(a) - headerKeys.indexOf(b))
      data.forEach(dataSource => {
        const elm = {}
        duplicates.forEach(key => {
          elm[header[key]] = dataSource[key]
        })
        excelData.push(elm)
      })

      const ws = XLSX.utils.json_to_sheet(excelData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'CauHoiKhaoSatDanhGia')
      XLSX.writeFile(wb, 'DanhSachCauHoiKhaoSatDanhGia.xlsx')
    },
    async handleImportSetOfCriteria() {
      this.isLoading = true
      try {
        const res = await this.importCriteria(this.dataExcel)
        if (res.code === 1) {
          this.showToast('Import thành công', 'CheckIcon', 'success')
          this.resetInputFile()
          await this.getMainDataFromStore()
          this.showTableImport = false
        } else {
          this.showToast(res.message, 'XCircleIcon', 'danger', 3000)
          this.resetInputFile()
          this.showTableImport = false
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger', 3000)
        this.resetInputFile()
        this.showTableImport = false
      } finally {
        this.isLoading = false
      }
    },
    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
    async importExcel(e) {
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if (files[0].name) {
        this.tenFile = files[0].name
      }
      const checkFileData = this.readFile(files)
      if (checkFileData && !checkFileData.status) {
        this.showToast('Có lỗi xảy ra', 'XCircleIcon', 'danger')
      }
    },
    readFile(files) {
      const res = {
        status: true,
        index: 0,
      }
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsname = workbook.SheetNames[0]
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            header: 0,
            defval: '',
          })
          const excellist = []
          // Edit data
          if (ws.length > 0) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < ws.length; i++) {
              excellist.push(ws[i])
            }
          } else {
            this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', 3000)
            this.resetInputFile()
            return
          }
          if (!Object.hasOwn(excellist[0], 'MA_PHIEU_KHAO_SAT_DANH_GIA')
            || !Object.hasOwn(excellist[0], 'LOAI_CAU_HOI')
            || !Object.hasOwn(excellist[0], 'TEN_CAU_HOI')
            || !Object.hasOwn(excellist[0], 'MA_CAU_HOI')
            || !Object.hasOwn(excellist[0], 'DIEM_TOI_DA')
            || !Object.hasOwn(excellist[0], 'SO_THU_TU')
            || !Object.hasOwn(excellist[0], 'YEU_CAU')
            || !Object.hasOwn(excellist[0], 'CO_THE_CHON_NHIEU_DAP_AN')
            || !Object.hasOwn(excellist[0], 'MA_CAU_HOI_CHA')
            || !Object.hasOwn(excellist[0], 'TEN_TUY_CHON')
            || !Object.hasOwn(excellist[0], 'DIEM_TUY_CHON')
            || !Object.hasOwn(excellist[0], 'SO_THU_TU_TUY_CHON')
          ) {
            this.showToast('File chưa đúng định dạng, vui lòng tải file mẫu', 'XCircleIcon', 'warning', 3000)
            this.resetInputFile()
            return
          }
          this.dataExcel = []
          // eslint-disable-next-line no-plusplus
          for (let k = 0; k < excellist.length; k++) {
            const item = excellist[k]
            let itemBefore = null
            if(k > 1 && excellist[k-1].MA_PHIEU_KHAO_SAT_DANH_GIA != "" && excellist[k-1].LOAI_CAU_HOI === CRITERIA_TYPES[1].value) {
              itemBefore = excellist[k-1]
            }
            
            if(item.MA_PHIEU_KHAO_SAT_DANH_GIA != "" && this.dataSend.code != item.MA_PHIEU_KHAO_SAT_DANH_GIA) {
              this.showToast(`Dữ liệu đầu vào trường thông tin [MA_PHIEU_KHAO_SAT_DANH_GIA] không khớp với mã phiếu đánh giá này, Kiểm tra thông tin dòng ${k + 1}`, 'XCircleIcon', 'warning', 3000)
              this.resetInputFile()
              return
            }

            if (item.MA_PHIEU_KHAO_SAT_DANH_GIA != '' && (item.LOAI_CAU_HOI === ''
            || item.TEN_CAU_HOI === '' || item.MA_CAU_HOI === '')) {
              this.showToast(`Dữ liệu đầu vào không được trống các trường thông tin [MA_PHIEU_KHAO_SAT_DANH_GIA, 
              LOAI_CAU_HOI, TEN_CAU_HOI,
              MA_CAU_HOI], Kiểm tra thông tin dòng ${k + 1}`, 'XCircleIcon', 'warning', 3000)
              this.resetInputFile()
              return
            }

            if (item.MA_PHIEU_KHAO_SAT_DANH_GIA != '' && item.LOAI_CAU_HOI !== '' && item.LOAI_CAU_HOI === CRITERIA_TYPES[1].value && (item.TEN_CAU_HOI === '' || item.MA_CAU_HOI === '' || item.YEU_CAU === '')) {
              this.showToast(`Dữ liệu đầu vào không được trống các trường thông tin [MA_PHIEU_KHAO_SAT_DANH_GIA, 
              LOAI_CAU_HOI, TEN_CAU_HOI,
              MA_CAU_HOI, YEU_CAU], Kiểm tra thông tin dòng ${k + 1}`, 'XCircleIcon', 'warning', 3000)
              this.resetInputFile()
              return
            }
            
            if (item.MA_PHIEU_KHAO_SAT_DANH_GIA === '' && item.LOAI_CAU_HOI === '' && item.TEN_CAU_HOI === '' && itemBefore && itemBefore.LOAI_CAU_HOI === CRITERIA_TYPES[1].value && item.MA_CAU_HOI_CHA === itemBefore.MA_CAU_HOI && (item.MA_CAU_HOI_CHA  === '' || item.TEN_TUY_CHON === ''
            || item.DIEM_TUY_CHON === '')) {
              this.showToast(`Dữ liệu đầu vào không được trống các trường thông tin [MA_CAU_HOI_CHA, TEN_TUY_CHON, 
              DIEM_TUY_CHON], Kiểm tra thông tin dòng ${k + 1}`, 'XCircleIcon', 'warning', 3000)
              this.resetInputFile()
              return
            }
            const obj = {
              rowNum: k + 1,
              organizationId: getUser().orgId,
              code: item.MA_PHIEU_KHAO_SAT_DANH_GIA,
              criteriaType: item.LOAI_CAU_HOI,
              criteriaName: item.TEN_CAU_HOI,
              criteriaCode: item.MA_CAU_HOI,
              maxPoint: item.DIEM_TOI_DA,
              criteriaOrderNo: item.SO_THU_TU,
              required: item.YEU_CAU,
              multipleSelect: item.CO_THE_CHON_NHIEU_DAP_AN,
              parentCriteriaCode: item.MA_CAU_HOI_CHA,
              optionName: item.TEN_TUY_CHON,
              optionCode: item.MA_TUY_CHON,
              optionPoint: item.DIEM_TUY_CHON,
              optionOrderNo: item.SO_THU_TU_TUY_CHON,
            }
            this.dataExcel.push(obj)
          }
          this.showTableImport = true
          // this.tableImport.data = this.dataExcel
          // this.tableImport.totalRows = this.dataExcel.length
        } catch (e) {
          alert(`Read failure!${e}`)
        }
      }
      fileReader.readAsBinaryString(files[0])
      return res
    },
    showToast(title, icon, variant, autoHideDelay = 1000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },
  },
  mounted() {
    const grid = document.querySelector('.vgt-responsive')
    grid.classList.add('overlay')
    grid.classList.add('custom')
  },
}
</script>
